
#invisible-form {
    --bs-border-width: 0px;
    color: #000;
    --bs-body-bg: transparent:
  }

  #invisible-form{
    height: 50vh !important;
  }
  
  #invisible-form:focus-within {
    outline: none; /* Remove focus outline when any child element receives focus */
  }

  textarea.form-control{
    height: 360px;
    overflow-y: auto; /* Enable vertical scrolling */
  }

  @media (max-width: 375px) {
    .submitContainer{
      margin-top:12px !important;
    }

    .categoryDropdown{
      margin-top: 0px !important;
    }

  }

  /* @media (max-width: 375px) {
    .camera-container{
    }

  } */

  .well {
    display: flex; /* Use 'flex', not 'flexbox' */
    flex-direction: row; /* This is default and could be omitted */
    justify-content: center; /* Centers items on the main axis */
    align-items: center; /* Centers items on the cross axis */
    margin-top: 0px;
    /* margin-right: 12px; */
    margin-left: 12px;
    margin-bottom: 12px;
    padding: 8px;
    width: 250px; /* Adjust as needed */
    float: right;
    border-radius: 8px;
    background-color: rgb(50, 50, 50);
    border-color: rgb(20,20,20);
  }

 

  #cameraWrapper{
    position: absolute; 
    top: 50% !important;        
    left: 50% !important;
    transform: translate(-50%, -50%) !important; 
    z-index:  10;
  }

  .microphone-icon, .camera-icon, .crop {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 8px; /* Adds a bit of margin between the icons */
    background-color: rgb(192, 192, 192);
    color: rgb(30,30,30);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .suggestion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 8px; /* Adds a bit of margin between the icons */
    background-color: rgb(192, 192, 192);
    color: rgb(30,30,30);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .quick-reply{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin: 0 8px; /* Adds a bit of margin between the icons */
    background-color: rgb(192, 192, 192);
    color: rgb(30,30,30);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  body {
    overscroll-behavior: none;
    touch-action: "pan-down";
  }

  html{
      overscroll-behavior: none;
      touch-action: "pan-down";

  }

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 8px; /* Adds a bit of margin between the icons */
    color: rgb(30,30,30);
  }

  .loader {
    border: 5px solid #f4f4f4; /* Light grey */
    border-top: 5px solid rgb(121, 84, 180); /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }
  

 .panorama_fish_eye{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0 8px; /* Adds a bit of margin between the icons */
    background-color: rgb(200,200,200);
    color: rgb(156, 0, 0);
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }

  .dropdown-menu {
    overflow-y: auto; /* enables scrolling within the dropdown */
  }
  

  @media (min-width: 768px) {
    .well {
      /* width: 136px; */
    }
    .microphone-icon{
      /* margin: 0px; */
      /* margin-right:4px; */
    }
    .quick-reply{
      /* margin-right: 0px; */
    }
    .theFeed{
    height: calc(100vh - env(safe-area-inset-bottom));
    object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    /* .camera-icon {
      margin: 0px;
      margin-right:4px;
    } */
}

@media (pointer:none), (pointer:coarse) {
  /* .camera-icon {
    display: ;
} */
}

.fade-in-text {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;  /* start with text fully transparent */
}

.visible-text {
  opacity: 1;  /* fully visible text */
}



  @media (max-width: 768px) {
    body {
      touch-action: manipulation;
    }
  }

  @media (min-width: 768px) {
    .entry{
      margin-bottom: '10px' !important;
    }
  }

  @media (min-width: 768px) {
    .entryComponent{
      margin-bottom: '-10px' !important;
    }
  }

 

  .no-scroll {
     overflow-y: hidden;
      position: fixed;
   }
  

  #category::placeholder {
    opacity: 0.5;
    color: rgb(252,252,252);
  }

  #content::placeholder {
    opacity: 0.5;
    color: rgb(252,252,252);
  }


  
  textarea {
    border: none;
    overflow: auto;
    overflow-y: auto; /* Enable vertical scrolling */
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
    border-style: none;
    border-color: transparent;
    outline: none;
    width: 100%;
    resize: vertical;
    min-height: 100px;
    font-size: 16px;
    transition: none;
    padding-left: 0px; /* Add this property to prevent leftward movement */

}

#content{
}
  
  textarea:focus {
    border: none;
    border-radius: 0px;
    background: transparent;
    -webkit-appearance: none;
    -moz-apperarance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding-left: 12px;
    resize: none;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border-color: transparent;

  }
  
  textarea:focus {
    width: 100%;
    transition: none;
    outline:none;
    border-color: transparent;

  }

  #category{
    border: none;
    overflow: auto;
    overflow-y: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: transparent;
    border-style: none;
    border-color: transparent;
    width: 100%;
    resize: none;
    font-size: 18px;
    transition: none;
    padding-left: 0px; /* Add this property to prevent leftward movement */

}

#category{
    margin-left:12px;

}

#submit:hover {
  background-color: rgb(80, 80, 80) !important;
  border-color: rgb(80, 80, 80) !important;
}

.alert-container {
  margin-left: auto; /* Move the success message to the right */
  margin-bottom: 10px; /* Add spacing between the success message and the Submit button */
}

/* Style for the container of the Submit button */
.btn-container {
  display: flex;
  align-items: flex-end; /* Align the bottom of the Submit button with the bottom of the success message */
}

.alert{
  margin-bottom: 0px;
}


#content{
  margin-left:0px;
  background-color: transparent;
  height: 48vh !important;
  padding-left: 0px; /* Add this property to prevent leftward movement */
  margin-left: 12px;
  overflow-y: auto; /* Enable vertical scrolling */

}



.btn .btn-primary{
  --bs-btn-border-color: rgb(121, 84, 180);
}

  
  #category:focus {
    border: none;
    border-radius: 0px;
    background: transparent;
    -webkit-appearance: none;
    -moz-apperarance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding-left: 0px;
    resize: none;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border-color: transparent;

  }
  
  #category:focus {
    width: 100%;
    transition: none;
    outline:none;
    border-color: transparent;

  }

  #content:focus {
    border: none;
    border-radius: 0px;
    background: transparent;
    -webkit-appearance: none;
    -moz-apperarance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    padding-left: 0px;
    resize: none;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    border-color: transparent;
  }
  
  #content:focus {
    width: 100%;
    transition: none;
    outline: none !important;
    border-color: transparent;
    overflow-y: auto;
  } 


  input:focus {
    outline: none !important;
  }

  input.middle:focus {
    outline-width: 0;
    outline: none !important;
  }

*:focus {
  outline: none !important;
}

[contenteditable="true"]:focus {
  outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

#yes:hover {
  background-color: rgb(90, 59, 138) !important;
  border-radius: rgb(90, 59, 138) !important;
}

.modal-content button {
  /* margin: 10px; */
  padding: 10px 20px;
  /* color: white; */
  /* background-color: white; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

#editedCategory{
  border-style: none !important;
}

.modal-close{
  /* background-color: #F7F8FA;
  color:#F7F8FA; */
  padding: 0px;
}

.modal-footer{
  justify-content: center;
}

#close{
  padding-left: 16px;
  padding-top: 12px;
  padding-bottom: 6px;
  cursor: pointer;
}

.modal-content{
  margin: 0 auto;
  /* border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); */
}

.badge-container .badge-additional:not(:first-child) {
  margin-left: 0;
  padding: 8px;
  /* margin-top: 8px; */
}

.modal-content button{
  padding:12px;
  /* background-color: #F7F8FA; */
}

#modalCopy{
  margin: 16px;
}



#dismiss{
padding-top: 12px;
padding-bottom: 12px;
padding-left: 12px;
padding-right: 12px;
background-color:rgb(245,245,245);
}
  