.selected-node {
    stroke: white;
    stroke-width: 3px;
    animation: radiate 1.5s infinite;
  }

  
  @keyframes radiate {
    0% {
      stroke-width: 3px;
      stroke-opacity: 1;
    }
    50% {
      stroke-width: 12px;
      stroke-opacity: 0.5;
    }
    100% {
      stroke-width: 3px;
      stroke-opacity: 1;
    }
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  #syncButton{
    --bs-btn-disabled-bg: rgb(40,40,40);
    --bs-btn-disabled-border-color: rgb(60,60,60);
}

/* ContentMap.css */
.cluster-title {
  font-size: 14px;
  font-weight: bold;
  fill: white;
}


  