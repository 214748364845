.button-hover-effect {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease; /* Smooth transition for all states */
  }
  
  /* Button Hover */
  .button-hover-effect:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Subtle drop shadow */
    transform: translateY(-2px); /* Adds a slight lift */
  }
  
  /* Home.css */
@media (min-width: 768px) {
    .form-container {
      margin-left: -84px;
    }
  }

  @media (max-width: 768px) {
    .form-container {
      padding: 8px;
      margin: auto;
      margin-bottom: 24px;
    }
  }

  .button-hover-effect .arrow {
    transition: transform 0.3s ease-in-out; /* Smooth transition for arrow movement */
  }
  
  /* Arrow Hover */
  .button-hover-effect:hover .arrow {
    transform: translateX(5px); /* Move arrow 5px to the right */
  }
  
  /* Align checkbox and label vertically */
  .checkbox-container .form-check-input {
    width: 20px; /* Adjust size */
    height: 20px;
    accent-color: #ffc107; /* Warning yellow */
    border: 1px solid #ffc107; /* Add border for compatibility */
    appearance: auto; /* Ensure the browser uses custom styling */
    border-radius: 16px; /* Rounds the corners of the checkbox itself */
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and label */
    border-radius: 16px; /* Rounds the corners of the checkbox itself */

  }
  
  .checkbox-container .form-check-label {
    color: black;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    border-radius: 16px; /* Rounds the corners of the checkbox itself */

  }
  
  

  


  