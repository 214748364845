.modal-content button{
    background-color: transparent;
}

.modal-header .btn-close{
    background-color: transparent;
}

.modal.show .modal-dialog{
    margin-top: 12vh;
}

@media (max-width: 768px){
  .cosine-sim-card{
  width: 100%;
  max-height: 160px;
  }
}



.btn-close{
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="grey" class="bi bi-x" viewBox="0 0 16 16"><path d="M3.646 3.646a.5.5 0 0 1 .708 0L8 7.293l3.646-3.647a.5.5 0 0 1 .708 0 .5.5 0 0 1 0 .708L8.707 8l3.647 3.646a.5.5 0 0 1 0 .708a.5.5 0 0 1-.708 0L8 8.707l-3.646 3.647a.5.5 0 0 1-.708 0a.5.5 0 0 1 0-.708L7.293 8L3.646 4.354a.5.5 0 0 1 0-.708z"/></svg>');
  background-size: 24px 24px;
  font-weight: bold; /* Add this line to make the X bold */
}

#formCollectionName.form-control{
  background-color: rgb(100,100,100);
  color: rgb(200,200,200);
}
.btn.btn-primary{
  --bs-btn-border-color: rgb(121, 84, 180);
}

.custom-input::placeholder {
  color: rgb(245, 245, 245); /* Your desired placeholder color */
}

.form-control{
  background-color: rgb(40,40,40);
  border-color: rgb(50,50,50);
  color: rgb(245,245,245);
}

.form-control:focus{
  background-color: rgb(35,35,35);
  border-color: rgb(50,50,50);
  color: rgb(245,245,245);
}

.form-control::placeholder{
  color: rgb(180,180,180);
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: #fff;
    /* border-radius: 8px;
    padding: 20px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    border: 0px;
    padding: 0px; 
    margin: 0 auto;
  }
  
  .modal-content p {
    margin: 0;
    text-align: center;

  }

  #content{
    margin-top: 10vh;
  }

  #dismiss{
    color: #6c757d;
  }

  .cosine-sim-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .cosine-sim-card {
    background-color: rgb(53, 53, 53);
    min-width: 240px;
    height: 240px;
    color: rgb(245, 245, 245);
    border: 2px solid rgb(33,33,33);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .cosine-sim-card .card-body {
    text-align: center;
    text-align: center; /* Centers text horizontally */
    display: flex; /* Enables the use of flexbox properties */
    flex-direction: column; /* Aligns children elements in a column */
   justify-content: center; /* Centers content vertically in the flex container */
    align-items: center; /* Centers content horizontally in the flex container */
    cursor: 'pointer';
  }
  
  .cosine-sim-card .card-title {
    font-size: 18px;
    color: rgb(245, 245, 245);
  }
  
  .cosine-sim-card .card-text {
    font-size: 14px;
    color: rgb(200, 200, 200);
    margin-bottom: 15px;
  }

 

  @media (max-width: 992px) {
    .modal-content {
    }
  }

  @media (max-width: 768px) {
    .modal-content {
    }
  }

  @media (max-width: 375px) {
    .modal-content {
    }
  }

  @media (max-width: 992px){
    .promptButtonContainer{
      display: flex;
      flex-direction: row;
    }
  }

  @media (max-width: 768px){
    .promptButtonContainer{
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 375px){
    .promptButtonContainer{
      display: flex;
      flex-direction: column;
    }
  }

