.icon-half-size {
    font-size: 16px; /* Adjust the size as needed */
    color: rgb(100,100,100);
  }

  #yes{
    color: #fff;
    background-color: rgb(121, 84, 180);
    border-color: rgb(121, 84, 180);
    width: 30vw;
    margin: 10px;
    max-width: 300px;

  }

  .card-body{
    padding: 16px;

  }

  @keyframes colorPulse {
    0%, 100% {
      background-color: rgb(109, 68, 176); /* Original color */
    }
    50% {
      background-color: rgb(86, 56, 134); /* Slightly darker purple */
    }
  }

  @keyframes colorPulseRainbow {
    0% { color: rgba(255, 0, 0, .4); } /* red */
    14% { color: rgba(255, 165, 0, .4); } /* orange */
    28% { color: rgba(255, 255, 0, .4); } /* yellow */
    42% { color: rgba(0, 128, 0, .4); } /* green */
    57% { color: rgba(0, 0, 255, .4); } /* blue */
    71% { color: rgba(75, 0, 130, .4); } /* indigo */
    85% { color: rgba(238, 130, 238, .4); } /* violet */
    100% { color: rgba(255, 0, 0, .4); } /* red */
  }
  

  @keyframes radialPulse {
    0%, 100% { background-size: 500% 500%; }
    50% { background-size: 200% 200%; }
  }
  

  @keyframes colorPulseRainbowBackwards {
    0%, 100% { background-color: green; }
    14% { background-color: orange; }
    28% { background-color: yellow; }
    42% { background-color: green; }
    57% { background-color: blue; }
    71% { background-color: indigo; }
    85% { background-color: violet; }
  }

  #yes:hover {
    background-color: rgb(90, 59, 138) !important;
    border-radius: rgb(90, 59, 138) !important;
  }

  .modal-content button {
    /* margin: 10px; */
    padding: 10px 20px;
    /* color: white; */
    /* background-color: white; */
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  /* Ensure the dropdown menu is displayed above other elements */
  .custom-dropdown-menu {
    position: absolute !important;
    z-index: 1050 !important; /* Ensure it's above other elements */
  }
  
  .card-container {
    overflow: visible !important;
  }  


  #editedCategory{
    border-style: none !important;
  }

  .modal-close{
    background-color: #F7F8FA;
    color:#F7F8FA;
    padding: 0px;
  }

  #close{
    padding-left: 16px;
    padding-top: 12px;
    padding-bottom: 6px;
    cursor: pointer;

  }

  .modal-content{
    /* max-width: 400px; */
    /* border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius); */
  }

  .badge-container .badge {
    font-weight: 400;
    padding: 8px;
    margin-bottom: 8px;
  }
  
  .badge-container .badge-first {
    margin-right: 8px;
    --bs-badge-border-radius: 16px;
  }
  
  .badge-container .badge-additional:not(:first-child) {
    margin-left: 0;
    padding: 8px;
    --bs-badge-border-radius: 16px;
    /* margin-top: 8px; */
  }

  .btn-link{
    --bs-btn-color: rgb(200,200,200);
    --bs-btn-hover-color: rgb(230,230,230);
    --bs-btn-active-color: rgb(230,230,230);
  }

  .modal-content button{
    padding:12px;
    margin-left: auto;
  }

  .modal{
    --bs-modal-width: 60vw;
  }

  #modalCopy{
    margin: 16px;
  }

  #modalContentWrapper{
    padding-left: 12px;
    padding-right: 12px;
    padding-top:12px;
    padding-bottom:32px;
    max-width: 400px;
    background-color: rgb(53, 53, 53);
    border-bottom-right-radius: var(--bs-modal-inner-border-radius);
    border-bottom-left-radius: var(--bs-modal-inner-border-radius);

  }

  .modal-header{
    border-bottom: none;
    justify-content: space-between;
    background-color: rgb(40, 40, 40);
    padding-top:8px;
    padding-bottom:8px;
  }

 #dismiss{
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  background-color:rgb(245,245,245);
 }

 .fade-container {
  position: relative;
  max-height: 360px; /* Adjust based on your requirements */
  overflow: hidden;
  background-color: rgb(53, 53, 53); /* Background color of the card */
  -webkit-mask-image: -webkit-linear-gradient(top, rgb(53, 53, 53) 70%, rgba(53, 53, 53, 0.8) 90%, transparent 100%);
  mask-image: linear-gradient(180deg, rgb(53, 53, 53) 70%, rgba(53, 53, 53, 0.1) 90%, transparent 100%);
}

.modalVariant{
  width: 700px !important;
  max-width: 800px;
}

.text-content {
  position: relative;
  color: #e0e0e0; /* Text color */
}


 .card-text{
  height: 180px;
  overflow: hidden;
  font-size: 16px;
 }

 #cards {
  width: 340px;
  overflow: hidden;
  margin-bottom: 18px; /* Adds margin between cards vertically */
}

 @media (min-width: 375px) {
  #cards{
    max-width: 528px;
  }
}

@media (min-width: 768px) {
  #cards{
    max-width: 340px;
  }
}





