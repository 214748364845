/* Dashboard.css */

html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Heebo', sans-serif;  
    font-weight: 300;
    color: rgb(245, 245, 245);
    font-size: 18px;
    /* background-color: #1D1D1D; */
    background: rgb(20, 20, 20);
  }

  .rbt-menu>.dropdown-item{
    color: rgb(245, 245, 245);

  }

  .dropdown-menu{
    background-color:rgb(60, 60, 60);
    color: rgb(245,245,245);
  }

  .dropdown-item.active, .dropdown-item:active{
    background-color: rgb(166, 95, 248);
  }

  .dropdown-item.inactive, .dropdown-item{
    color: rgb(245,245,245);
  }

  #contact:hover {
    background-color: rgb(166, 95, 248) !important;
    border-color: rgb(166, 95, 248) !important;
  }

  .btn.btn-primary:hover {
    background-color: rgb(166, 95, 248);
    border-color: rgb(120,120,120);
  }
  

  .dropdown-item:not(.active):hover {
    background-color: rgb(70, 70, 70);
    color: white;
  }

 



  .title{
    font-family: 'Heebo', sans-serif;  
    font-weight: 400;
  }


  input.rbt-input-hint {
    color: rgb(245,245,245,0.3) !important;
  }

  #sortDropdown{
    background-color: rgb(40,40,40);
    border-color: rgb(60,60,60);
  }

  #contentDropdown{
    transform: translate(-170px, 20px) !important;
    z-index: 1000;
    -moz-box-shadow: 0 0 6px #ccc;
    -webkit-box-shadow: 0 0 6px #ccc;
    box-shadow: 0 0 12px #ccc;
  }
  

.dashboard-container {
    background-color: rgb(29, 29, 29);
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    height:100%;
  }

  #similarity-profile-message {
    background-color: #5cb85c; /* Light green color */
    color: #fff; /* Text color */
    text-align: center;
    padding: 10px;
    position: fixed;
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    display: none; /* Initially hidden */
  }

  .form-control{
    background-color: rgb(50,50,50);
    border-color: rgb(50,50,50);
    color: rgb(245,245,245);
  }
  
.form-control:focus{
    background-color: rgb(50,50,50);
    border-color: rgb(50,50,50);
    color: rgb(245,245,245);
  }
  
.form-control::placeholder{
    color: rgb(120,120,120);
  }


  

  .container{
    margin-left:0rem;
    --bs-gutter-x:1rem;
  }






  .footer {
    height: 32px;
    background-color: rgb(29, 29, 29);
  }

  /* Add this CSS to your stylesheet or style section */

.cosine-sim-container {
  display: flex; /* Use flexbox to create a row layout */
  flex-wrap: nowrap; /* Prevent cards from wrapping to the next line */
  overflow-x: auto; /* Add horizontal scrolling if needed */
}

.cosine-sim-card {
   /* Set a fixed width for the cards (adjust as needed) */
  height: 200px; /* Set a fixed height to make them square */
  border: 1px solid #ccc; /* Add a border for visualization (adjust as needed) */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow (adjust as needed) */
}

/* Add additional styling as needed for card content */
.cosine-sim-card .card-body {
  padding: 12px;
  text-align: center;
}

.cosine-sim-card .card-title {
  font-size: 14px;
}

.cosine-sim-card .card-text {
  font-size: 12px;
  color: #555;
}

.search-input-container {
  display: flex;
  align-items: center;
}

#variantModal .modal-body{
  background-color: #1D1D1D;
  text-align: left;
  min-height: 300px;
  max-height: 500px;
  
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: scroll;
}

.search-icon {
  margin-right: 8px; /* Adjust the spacing between the icon and input */
  font-size: 18px; /* Adjust the icon size */
}

.rbt{
  margin-right: 8px;
  margin-left: 10px;
} 

#row{
  display: flex;
  flex-direction: row !important;
}



@media (min-width: 475px) {

  .dropdown {
    /* margin-left:12px; */
    /* margin-bottom: 6px; */
  }

  .downloadBtn{
    margin-left: -4px;
  }

}

@media (max-width: 475px) {
  /* Adjust the layout for smaller screens */
  .menu-item {
    margin-top: 6px;
  }

  #downloadBtn{
    margin-left: 12px !important;
  }

  #row{
    display: flex;
    flex-direction: row !important;
  }

  .rbt{
    margin-bottom: 18px;
    width: 324px;
    height: 60px;
  } 

  .dropdown {
    /* margin-left:13px; */
  }

  .search-div {
    flex-direction: column; /* Place the search bar below the filter */
    align-items: flex-start;
    
  }
  .btn.btn-dark{
    margin-top: 0px;
  }
}